<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      />
    </v-card-title>
    <v-data-table
      class="datatable-with-row-click-action"
      :headers="headers"
      :items="users"
      :items-per-page="5"
      :search="search"
      :loading="isLoading"
      @click:row="handlePreview"
    >
      <template v-slot:item.active="item">
        {{ item.value ? "active" : "inactive" }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "UsersList",
  data() {
    return {
      search: null,
      isLoading: false,
      headers: [
        {
          text: "Full name",
          value: "fullname"
        },
        {
          text: "E-mail",
          value: "email"
        },
        {
          text: "Role",
          value: "role"
        },
        {
          text: "Status",
          value: "active"
        }
      ]
    };
  },
  computed: {
    ...mapState({
      users: state => state.api.management.user.users
    })
  },
  mounted() {
    if (this.users.length < 1) {
      this.handleGetUsers();
    }

    this.$root.$on("refresh-user-list", this.handleGetUsers);
  },
  beforeDestroy() {
    this.$root.$off("refresh-user-list", this.handleGetUsers);
  },
  methods: {
    ...mapActions({
      getUsers: "api/management/user/getUsers"
    }),
    handleGetUsers() {
      this.isLoading = true;
      this.getUsers()
        .catch(error => {
          console.log(error);
          this.$notifier.notify({
            message: "Cannot query users list",
            color: "error"
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handlePreview(item) {
      this.$router.push({
        name: "PanelUsersUser",
        params: {
          id: item._id
        }
      });
      console.log(item);
    }
  }
};
</script>
