<template>
  <div>
    <v-card-title>
      <navigation-breadcrumbs />
    </v-card-title>
    <v-card-text v-if="isLoading">
      <v-progress-circular :size="20" width="2" indeterminate />
      Please wait, loading data...
    </v-card-text>
    <v-card-text v-else>
      <v-row>
        <v-col>
          <v-row>
            <v-col>
              <v-btn
                color="success"
                @click="$router.push({ name: 'PanelUsersNewUser' })"
                :disabled="$route.name === 'PanelUsersNewUser'"
              >
                <v-icon small>mdi-plus</v-icon>
                New user
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <users-list />
            </v-col>
            <v-col v-if="$route.name !== 'PanelUsers'">
              <router-view />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import UsersList from "@/views/Panel/views/Users/components/UsersList";
import NavigationBreadcrumbs from "@/components/NavigationBreadcrumbs";
import { mapActions, mapState } from "vuex";
export default {
  name: "Users",
  components: { NavigationBreadcrumbs, UsersList },
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      markets: state => state.api.misc.markets
    })
  },
  created() {
    const requests = [];

    if (this.markets.length < 1) {
      requests.push(this.getMarkets());
    }

    if (requests.length > 0) {
      this.isLoading = true;
    }

    Promise.all(requests).finally(() => {
      this.isLoading = false;
    });
  },
  methods: {
    ...mapActions({
      getMarkets: "api/misc/getMarkets"
    })
  }
};
</script>
